import React, { useState, useEffect } from 'react'
import Tabla from '../../components/Tabla'
import useUser from '../../hooks/useUser'
import { useNavigate, Link  } from 'react-router-dom'
import { getPermisosEnPagina, getBasePath, getAuth, getImagenBasePath } from '../../Utils'
import SinPermisos from '../SinPermisos'
import axios from "axios"
import eliminarRegistro from '../../components/eliminarRegistro'
import { JsonToExcel } from "react-json-to-excel"

const Caducidades = () => {
    const {isLogged, permisosUsuario} = useUser();
    const navigate = useNavigate();
    const permisos = getPermisosEnPagina(permisosUsuario, 'Caducidades')
    const [data, setData] = useState([])
    const [excel, setExcel] = useState([])
    
    const columns = [
        {
            name: 'Tiempo',
            selector: row => row.tiempo,
            sortable: true,
            width: "21%"
        },
        {
            name: 'Color',
            selector: row => row.color,
            sortable: true,
            width: "21%"
        },
        {
            name: 'E-mail aviso',
            selector: row => row.emailAviso,
            sortable: true,
            width: "21%"
        },        {
            name: '',
            selector: row => row.ver,
            sortable: false,
            width: "75px",
            margin: "0"
        },
        {
            name: '',
            selector: row => row.editar,
            sortable: false,
            width: "75px",
            margin: "0"
        },
        {
            name:<Link to='#' style={{ color:"black",marginRight:"4px"}} className="btn cur-p btn-outline-primary" onClick={() => document.getElementsByClassName("download-button")[0].click()}><i className="ti ti-cloud-down"></i></Link>,
            selector: row => row.borar,
            sortable: false,
            width: "75px",
            margin: "0"
        }
    ];

    const cargarData = async () => {
        const {data: caducidades} = await axios.get(`${getBasePath()}/caducidades`, getAuth());
        let caducidadesExcel = []
        caducidades.forEach(el =>  {
            //
            //Definimos los datos del excel
            //
            const caducidadesObj = {"Tiempo": el.tiempo, 
                             "Color": el.color}
            caducidadesExcel.push(caducidadesObj)
            //
            //Ahora definimos los botones
            //
            if(permisos.editar) 
                el.editar = (<div><Link to={`/caducidadesform/editar/${el.id}`} style={{ color:"black",marginRight:"4px"}} className="btn cur-p btn-outline-primary"><i className="ti ti-pencil"></i></Link></div>)
            if(permisos.borrar) 
                el.borar = (<div><Link to={`/caducidades`} onClick={() => eliminarRegistro(el.id, 'caducidades')} style={{ color:"black",marginRight:"4px"}} className="btn cur-p btn-outline-primary"><i className="ti ti-trash"></i></Link></div>)
            el.ver = (<div><Link to={`/caducidadesform/ver/${el.id}`} style={{ color:"black",marginRight:"4px"}} className="btn cur-p btn-outline-primary"><i className="ti ti-eye"></i></Link></div>)
        })
        setData(caducidades)
        setExcel(caducidadesExcel)
    }

    useEffect(() => {
        cargarData()
    },[])

    useEffect(() => {
        if (!isLogged) { navigate("/login"); }
    }, [isLogged]);

    return isLogged && permisos.ver ? (<div>
        <div style={{display:"none"}}><JsonToExcel id="excel" data={excel} fileName="caducidades" /></div>
        <div className="bgc-white p-20 bd" style={{ marginBottom: "10px" }}>
            <h6 className="c-grey-900">Caducidades</h6>
            { permisos.crear &&
                <div className="mT-30">
                    <Link className="btn btn-success btn-color" to="/caducidadesform/nuevo">Nuevo</Link>
                </div>
            }
        </div>
        <Tabla columns={columns} data={data} selectableRows />
    </div>) : (<SinPermisos/>)
}

export default Caducidades;