import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser'
import { getBasePath, getAuth, getPermisosEnPagina, permisoAdecuado, getImagenBasePath, toastAlert } from '../../Utils'
import axios from "axios"
import { Input, Form } from 'reactstrap'
import Swal from 'sweetalert2'
import SinPermisos from '../SinPermisos'

const ConceptosFacturas = () => {
    const navigate = useNavigate();
    const {isLogged, permisosUsuario, hotelSelected} = useUser();
    const permisos = getPermisosEnPagina(permisosUsuario, 'Conceptos Facturas')
    const datos = useParams() //Obtenemos los parámetros pasados por url { accion: "edit", id: "1" }
    const [nombre, setNombre] = useState('')
    const [referencia, setReferencia] = useState('')
    const [iva, setIva] = useState('')

    useEffect(() => {
        if (!isLogged) { navigate("/login"); }
    }, [isLogged]);

    const getDatosConceptosFacturas = async () => {
        try {
            const {data: habitaciones} = await axios.get(`${getBasePath()}/conceptosfacturas/${datos.id}`, getAuth());
            setNombre(habitaciones.nombre)
            setReferencia(habitaciones.referencia)
            setIva(habitaciones.iva)
        } catch (error) {
            console.error(error)
            Swal.fire({
                title: 'Error',
                text: 'Concepto Factura no encontrado',
                icon: 'error'
              })
        }
    }

    const submitConceptosFacturas = async (conceptosfacturasObj) => {
        try {
            if (datos.accion === 'nuevo') 
                await axios.post(`${getBasePath()}/conceptosfacturas`, conceptosfacturasObj, getAuth());
            if (datos.accion === 'editar') 
            await axios.put(`${getBasePath()}/conceptosfacturas/${datos.id}`, conceptosfacturasObj, getAuth());
            toastAlert('Conceptos Facturas')
            navigate("/conceptosfacturas")
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Error en la creación del Concepto de la Factura',
                icon: 'error'
              })
            console.error(error)
        }
    }

    useEffect(() => {
        if(datos.accion !== 'nuevo') { getDatosConceptosFacturas() }
    }, [datos])

    function handleSubmit (e) {
        e.preventDefault()
        const conceptosfacturasObj = {
            id: datos.id,
            nombre,
            referencia,
            iva
        }
        submitConceptosFacturas(conceptosfacturasObj)
    }
    
    return isLogged && permisoAdecuado(permisos, datos.accion) ?(
        <div className="row gap-20 " style={{ position:"relative",height:"1116px" }} >
            <div className="masonry-item col-md-12" style={{ position:"absolute", left:"0%", top:"0px" }} >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{ datos.accion.charAt(0).toUpperCase() + datos.accion.slice(1).toLowerCase() } Conceptos Facturas</h6>
                    <div className="mT-30">
                        <Form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className="mb-3 col-md-4" id="dos">
                                    <label className="form-label">Nombre *</label>
                                    <Input type="text" className="form-control" id="nombre" aria-describedby="nombre" disabled={datos.accion === 'ver'} value={nombre} onChange={({target}) => setNombre(target.value)} required />
                                </div>
                                <div className="mb-3 col-md-4" id="dos">
                                    <label className="form-label">Referencia *</label>
                                    <Input type="text" className="form-control" id="referencia" aria-describedby="referencia" disabled={datos.accion === 'ver'} value={referencia} onChange={({target}) => setReferencia(target.value)} maxlength={5} required />
                                </div>
                                <div className="mb-3 col-md-4" id="dos">
                                    <label className="form-label">Iva *</label>
                                    <Input type="text" className="form-control" id="iva" aria-describedby="iva" disabled={datos.accion === 'ver'} value={iva} onChange={({target}) => setIva(target.value)} maxlength={2} required />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary btn-color">Guardar</button>
                            <Link className="btn btn-secondary btn-color" to="/conceptosfacturas" style={{marginLeft: '10px'}}>Atras</Link>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    ): (<SinPermisos/>)
}

export default ConceptosFacturas