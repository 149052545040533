import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser'
import { getBasePath, getAuth, getPermisosEnPagina, permisoAdecuado, getImagenBasePath, toastAlert } from '../../Utils'
import axios from "axios"
import { Input, Form } from 'reactstrap'
import Swal from 'sweetalert2'
import SinPermisos from '../SinPermisos'
import Select from 'react-select'

const Caducidadesform = () => {
    const navigate = useNavigate();
    const {isLogged, permisosUsuario, hotelSelected} = useUser();
    const permisos = getPermisosEnPagina(permisosUsuario, 'Caducidades')
    const datos = useParams() //Obtenemos los parámetros pasados por url { accion: "edit", id: "1" }
    const [tiempo, setTiempo] = useState('')
    const [color, setColor] = useState('')
    const [emailAviso, setEmailAviso] = useState('')

    useEffect(() => {
        if (!isLogged) { navigate("/login"); }
    }, [isLogged]);

    const getDatosCaducidades = async () => {
        try {
            const {data: caducidades} = await axios.get(`${getBasePath()}/caducidades/${datos.id}`, getAuth());
            setTiempo(caducidades.tiempo)
            setColor(caducidades.color)
            setEmailAviso(caducidades.emailAviso)
        } catch (error) {
            console.error(error)
            Swal.fire({
                title: 'Error',
                text: 'Caducidad no encontrada',
                icon: 'error'
              })
        }
    }

    const submitCaducidades = async (caducidadesObj) => {
        try {
            if (datos.accion === 'nuevo') 
                await axios.post(`${getBasePath()}/caducidades`, caducidadesObj, getAuth());
            if (datos.accion === 'editar') 
                await axios.put(`${getBasePath()}/caducidades/${datos.id}`, caducidadesObj, getAuth());
            toastAlert('Caducidades')
            navigate("/caducidades")
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Error en la creación de la caducidades',
                icon: 'error'
              })
            console.error(error)
        }
    }

    useEffect(() => {
        if(datos.accion !== 'nuevo') { getDatosCaducidades() }
    }, [datos])

    function handleSubmit (e) {
        e.preventDefault()
        const caducidadesObj = {
            id: datos.id,
            tiempo,
            color,
            emailAviso
        }
        submitCaducidades(caducidadesObj)
    }

    const getColoresOptions = () => {
        const colores = [{ nombre: 'yellow' }, { nombre: 'red' }, { nombre: 'green' }]
        const options = colores.map(({ nombre }) => ({ value: nombre, label: nombre }))
        return options
      }
    
    return isLogged && permisoAdecuado(permisos, datos.accion) ?(
        <div className="row gap-20 " style={{ position:"relative",height:"1116px" }} >
            <div className="masonry-item col-md-12" style={{ position:"absolute", left:"0%", top:"0px" }} >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{ datos.accion.charAt(0).toUpperCase() + datos.accion.slice(1).toLowerCase() } Habitación</h6>
                    <div className="mT-30">
                        <Form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className="mb-3 col-md-4" id="dos">
                                    <label className="form-label">Tiempo en días *</label>
                                    <Input type="text" className="form-control" id="tiempo" aria-describedby="tiempo" disabled={datos.accion === 'ver'} value={tiempo} onChange={({target}) => setTiempo(target.value)} required />
                                </div>
                                <div className="mb-3 col-md-4" id="dos">
                                    <label className="form-label">Color *</label>
                                    <Select 
                                        options={getColoresOptions()} 
                                        id="colores"
                                        value={getColoresOptions().find(el => el.value == color)}
                                        onChange={(target) => setColor(target.value)}
                                        isRequired={ true }
                                    />
                                </div>
                                <div className="mb-3 col-md-4" id="dos">
                                    <label className="form-label">Emails Aviso *</label>
                                    <Input type="text" className="form-control" id="emailAviso" aria-describedby="emailAviso" disabled={datos.accion === 'ver'} value={emailAviso} onChange={({target}) => setEmailAviso(target.value)} required />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary btn-color">Guardar</button>
                            <Link className="btn btn-secondary btn-color" to="/caducidades" style={{marginLeft: '10px'}}>Atras</Link>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    ): (<SinPermisos/>)
}

export default Caducidadesform