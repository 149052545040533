import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser'
import { getBasePath, getAuth, getPermisosEnPagina, permisoAdecuado, getImagenBasePath, toastAlert } from '../../Utils'
import axios from "axios"
import { Input, Form } from 'reactstrap'
import Swal from 'sweetalert2'
import SinPermisos from '../SinPermisos'

const Albaranesform = () => {
    const navigate = useNavigate();
    const {isLogged, permisosUsuario, dataUsuario} = useUser();
    const empresaId = dataUsuario.empresaId
    const permisos = getPermisosEnPagina(permisosUsuario, 'Albaranes')
    const datos = useParams() //Obtenemos los parámetros pasados por url { accion: "edit", id: "1" }
    const [fecha, setFecha] = useState('')

    useEffect(() => {
        if (!isLogged) { navigate("/login"); }
    }, [isLogged]);

    const getDatosAlbaranes = async () => {
        try {
            const {data: albaranes} = await axios.get(`${getBasePath()}/albaranes/${datos.id}`, getAuth());
            setFecha(albaranes.fecha)
        } catch (error) {
            console.error(error)
            Swal.fire({
                title: 'Error',
                text: 'Albarán no encontrado',
                icon: 'error'
              })
        }
    }

    const submitAlbaranes = async (albaranesObj) => {
        try {
            if (datos.accion === 'nuevo') 
                await axios.post(`${getBasePath()}/albaranes`, albaranesObj, getAuth());
            if (datos.accion === 'editar')
                await axios.put(`${getBasePath()}/albaranes/${datos.id}`, albaranesObj, getAuth());
 
            toastAlert('Albaranes')
            navigate("/albaranes")
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Error en la creación del Albarán',
                icon: 'error'
              })
            console.error(error)
        }
    }

    useEffect(() => {
        if(datos.accion !== 'nuevo') { getDatosAlbaranes() }
    }, [datos])

    function handleSubmit (e) {
        e.preventDefault()
        const albaranesObj = {
            id: datos.id,
            fecha
        }
        submitAlbaranes(albaranesObj)
    }

    return isLogged && permisoAdecuado(permisos, datos.accion) ? (
        <div className="row gap-20 " style={{ position:"relative",height:"1116px" }} >
            <div className="masonry-item col-md-12" style={{ position:"absolute", left:"0%", top:"0px" }} >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{ datos.accion.charAt(0).toUpperCase() + datos.accion.slice(1).toLowerCase() } Registro</h6>
                    <div className="mT-30">
                        <Form onSubmit={handleSubmit}>                           
                            <div className='row'>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Fecha *</label>
                                    <Input type="text" className="form-control" id="fecha" value={fecha} onChange={({target}) => setFecha(target.value)} disabled={datos.accion === 'ver'} required />
                                </div>                                
                            </div>                            
                            <button type="submit" className="btn btn-primary btn-color">Guardar</button>
                            <Link className="btn btn-secondary btn-color" to="/albaranes" style={{marginLeft: '10px'}}>Atras</Link>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    ): (<SinPermisos/>)
}
export default Albaranesform