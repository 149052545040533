import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser'
import { getBasePath, getAuth, getPermisosEnPagina, permisoAdecuado, getImagenBasePath, toastAlert } from '../../Utils'
import axios from "axios"
import Select from 'react-select'
import { Input, Form } from 'reactstrap'
import Swal from 'sweetalert2'
import SinPermisos from '../SinPermisos'

const Hotelesform = () => {
    const navigate = useNavigate();
    const {isLogged, permisosUsuario, dataUsuario} = useUser();
    const empresaId = dataUsuario.empresaId
    const permisos = getPermisosEnPagina(permisosUsuario, 'Hoteles')
    const datos = useParams() //Obtenemos los parámetros pasados por url { accion: "edit", id: "1" }
    const [nombre, setNombre] = useState('')
    const [imagen, setImagen]  = useState('')
    const [newImagen, setNewImagen] = useState('')
    const [direccion, setDireccion]  = useState('')
    const [contacto, setContacto]  = useState('')
    const [telefono, setTelefono]  = useState('')
    const [domicilioSocial, setDomicilioSocial] = useState('')
    const [domicilioFiscal, setDomicilioFiscal] = useState('')
    const [direccionEnvioFactura, setDireccionEnvioFactura] = useState('')
    const [formaPago, setFormaPago] = useState('')
    const [domicilioPago, setDomicilioPago] = useState('')
    const [porcentaje, setPorcentaje] = useState('')
    const [arrayConceptos, setArrayConceptos] = useState([])
    const [tab, setTab] = useState("hotel")
    const [nombreCliente, setNombreCliente] = useState('')
    const [tipoCifNif, setTipoCifNif]  = useState('')
    const [cifNif, setCifNif]  = useState('')

    useEffect(() => {
        if (!isLogged) { navigate("/login"); }
    }, [isLogged]);

    const options = [
        { value: 'Cif', label: 'Cif' },
        { value: 'Nif', label: 'Nif' },
        { value: 'DNI', label: 'DNI' },
        { value: 'Pasaporte', label: 'Pasaporte' }
    ]

    const getDatosHoteles = async () => {
        try {
            const {data: hoteles} = await axios.get(`${getBasePath()}/hoteles/${datos.id}`, getAuth());
            setNombre(hoteles.nombre)
            setImagen(hoteles.imagen)
            setDireccion(hoteles.direccion)
            setContacto(hoteles.personaContacto)
            setTelefono(hoteles.telefonoContacto)
            setDomicilioSocial(hoteles.domicilioSocial)
            setDomicilioFiscal(hoteles.domicilioFiscal)
            setDireccionEnvioFactura(hoteles.direccionEnvioFactura)
            setFormaPago(hoteles.formaPago)
            setDomicilioPago(hoteles.domicilioPago)
            setPorcentaje(hoteles.porcentaje)
            setTipoCifNif(hoteles.tipoCifNif)
            setCifNif(hoteles.cifnif)
            setNombreCliente(hoteles.nombreCliente)
            
            const {data: arrayConceptos} = await axios.get(`${getBasePath()}/conceptoshoteles/${datos.id}`, getAuth());
            setArrayConceptos(arrayConceptos)
        } catch (error) {
            console.error(error)
            Swal.fire({
                title: 'Error',
                text: 'Hoteles no encontrados',
                icon: 'error'
              })
        }
    }

    const submitHoteles = async (hotelesObj) => {
        try {
            if (datos.accion === 'nuevo') 
                await axios.post(`${getBasePath()}/hoteles`, hotelesObj, getAuth());
            if (datos.accion === 'editar') 
                await axios.post(`${getBasePath()}/hotelesupdate`, hotelesObj, getAuth());
            toastAlert('Hoteles')
            navigate("/hoteles")
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Error en la creación de hoteles',
                icon: 'error'
              })
        }
    }

    useEffect(() => {
        if(datos.accion !== 'nuevo') { getDatosHoteles() }
    }, [])

    function handleSubmit (e) {
        e.preventDefault()
        // Obtener todos los elementos Input cuyo id contiene la cadena "importe-concepto-"
        const importeConceptos = document.querySelectorAll("*[id*='importeConcepto-']")
        // Obtiene y recorre los elementos y obtener sus valores
        let importeConcepto = []
        for (let i = 0; i < importeConceptos.length; i++) {
             importeConcepto[importeConceptos[i].id.replace('importeConcepto-', '')] = importeConceptos[i].value
        }
        
        let relleno = ''

        if (document.getElementById("direccion").value == '') relleno = 'Direccion'
        if (document.getElementById("telefonoContacto").value == '') relleno = 'Teléfono de Contacto'
        if (document.getElementById("personaContacto").value == '') relleno = 'Dontacto'
        if (document.getElementById("nombreCliente").value == '') relleno = 'Nombre Cliente para factura'
        if (document.getElementById("cifnif").value == '') relleno = 'Documento'
        if (document.getElementById("domicilioSocial").value == '') relleno = 'Domicilio Social'
        if (document.getElementById("domicilioFiscal").value == '') relleno = 'Domicilio Fiscal'
        if (document.getElementById("direccionEnvioFactura").value == '') relleno = 'Direccion Envio Factura'
        if (document.getElementById("formaPago").value == '') relleno = 'Forma Pago'
        if (document.getElementById("domicilioPago").value == '') relleno = 'Domicilio Pago'
        if (document.getElementById("porcentaje").value == '') relleno = 'Porcentaje'

        if (relleno !== ''){
            Swal.fire({
                title: 'Error',
                text: `El campo ${relleno} debe estar relleno`,
                icon: 'error'
              })
        }
        else{

            const hotelesObj = {
                id: datos.id,
                nombre,
                empresaId,
                imagen,
                direccion,
                personaContacto: contacto,
                telefonoContacto: telefono,
                domicilioSocial,
                domicilioFiscal,
                direccionEnvioFactura,
                formaPago,
                domicilioPago, 
                porcentaje,
                importeConcepto,
                tipoCifNif,
                cifNif,
                nombreCliente
            }
            console.error(hotelesObj)
            submitHoteles(hotelesObj)
        }
    }
    const handleImageChange = (e) => {
        const reader = new FileReader()
        reader.onload = function (e) {
            setImagen(e.target.result)
            setNewImagen(e.target.result)
        }
        reader.readAsDataURL(e.target.files[0])
    }

    return isLogged && permisoAdecuado(permisos, datos.accion) ? (
        <div className="row gap-20 " style={{ position:"relative",height:"1116px" }} >
            <div className="masonry-item col-md-12" style={{ position:"absolute", left:"0%", top:"0px" }} >
                <div className="bgc-white p-20 bd">
                    <div className="mT-30">
                        <Form onSubmit={handleSubmit} id="formulario">
                            <div style={{paddingBottom:'20px'}}>
                                <input type="hidden" id="imagen_antigua" value={imagen} />
                                <div className='row'>
                                    <div className="mb-3 col-md-4">
                                        <img id="imagen" 
                                            className='user-avatar rounded mr-2 my-25 cursor-pointer'
                                            src={newImagen || `${getImagenBasePath()}/${imagen}`}
                                            alt='Imagen hotel'
                                            height='90px'
                                            width='90px'
                                        />
                                        <label className="form-label">Imagen</label>
                                        <Input 
                                            type="file" 
                                            className="form-control" 
                                            onChange={(e) => handleImageChange(e)} 
                                            disabled={datos.accion === 'ver'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <a className={tab === "hotel" ? 'nav-link active' : 'nav-link'} id="bloqueDatosEmpresa" href="#" onClick={() => setTab('hotel')}>Datos Empresa</a>
                                </li>
                                <li className="nav-item">
                                    <a className={tab === "facturacion" ?  'nav-link active' : 'nav-link'} id="bloqueDatosFacturacion" href="#" onClick={() => setTab('facturacion')}>Datos Facturación</a>
                                </li>
                                <li className="nav-item">
                                    <a className={tab === "conceptos" ?  'nav-link active' : 'nav-link'} href="#" onClick={() => setTab('conceptos')}>Datos Conceptos</a>
                                </li>
                            </ul>
                            <div style={{paddingBottom:"20px", paddingTop:"20px"}}>
                                <div style={{ display: tab === "hotel" ? "block" : "none" }}>
                                    <div className='row'>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Nombre *</label>
                                            <Input type="text" className="form-control" id="nombre" defaultValue={nombre} onChange={({target}) => setNombre(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Dirección *</label>
                                            <Input type="text" className="form-control" id="direccion" defaultValue={direccion} onChange={({target}) => setDireccion(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Teléfono de Contacto *</label>
                                            <Input type="text" className="form-control" id="telefonoContacto" defaultValue={telefono} onChange={({target}) => setTelefono(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Persona de Contacto *</label>
                                            <Input type="text" className="form-control" id="personaContacto" defaultValue={contacto} onChange={({target}) => setContacto(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: tab === "facturacion" ? "block" : "none" }}>
                                    <div className='row'>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Nombre Cliente para factura *</label>
                                            <Input type="text" className="form-control" id="nombreCliente" defaultValue={nombreCliente} onChange={({target}) => setNombreCliente(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">CifNif</label>
                                            <Select 
                                                options={options} 
                                                id="tipoCifNif"
                                                value={options.find(el => el.value === tipoCifNif)}
                                                onChange={(target) => setTipoCifNif(target.value)}
                                                isDisabled={datos.accion === 'ver'}
                                            />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Documento *</label>
                                            <Input type="text" className="form-control" id="cifnif" aria-describedby="cifnif" value={cifNif} onChange={({target}) => setCifNif(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Domicilio social *</label>
                                            <Input type="text" className="form-control" id="domicilioSocial" defaultValue={domicilioSocial} onChange={({target}) => setDomicilioSocial(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Domicilio Fiscal *</label>
                                            <Input type="text" className="form-control" id="domicilioFiscal" defaultValue={domicilioFiscal} onChange={({target}) => setDomicilioFiscal(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Dirección envio factura *</label>
                                            <Input type="text" className="form-control" id="direccionEnvioFactura" defaultValue={direccionEnvioFactura} onChange={({target}) => setDireccionEnvioFactura(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Forma de Pago *</label>
                                            <Input type="text" className="form-control" id="formaPago" defaultValue={formaPago} onChange={({target}) => setFormaPago(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Domicilio de Pago *</label>
                                            <Input type="text" className="form-control" id="domicilioPago" defaultValue={domicilioPago} onChange={({target}) => setDomicilioPago(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                        <div className="mb-3 col-md-4">
                                            <label className="form-label">Porcentaje *</label>
                                            <Input type="text" className="form-control" id="porcentaje" value={porcentaje} onChange={({target}) => setPorcentaje(target.value)} disabled={datos.accion === 'ver'} required />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: tab === "conceptos" ? "block" : "none" }}>
                                    {arrayConceptos.map((concept) => (
                                        <div className='row'>
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Concepto *</label>
                                                <Input type="text" className="form-control" value={concept.nombre} disabled />
                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Referencia *</label>
                                                <Input type="text" className="form-control" value={concept.referencia} disabled />
                                            </div>
                                            <div className="mb-3 col-md-4">
                                                <label className="form-label">Importe defecto *</label>
                                                <Input type="number" id={`importeConcepto-${concept.id}`} className="form-control" defaultValue={concept.importe} disabled={datos.accion === 'ver'}  style={{textAlign: 'right', width: '100%', marginTop: '0', height: '37px'}} />
                                            </div>       
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <button type="button" onClick={handleSubmit} className="btn btn-primary btn-color">Guardar</button>
                                <Link className="btn btn-secondary btn-color" to="/hoteles" style={{marginLeft: '10px'}}>Atras</Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    ): (<SinPermisos/>)
}

export default Hotelesform