import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser'
import { getBasePath, getAuth, getPermisosEnPagina, permisoAdecuado, getImagenBasePath, toastAlert } from '../../Utils'
import axios from "axios"
import { Input, Form } from 'reactstrap'
import Swal from 'sweetalert2'
import SinPermisos from '../SinPermisos'

const Tiposform = () => {
    const navigate = useNavigate();
    const {isLogged, permisosUsuario, dataUsuario} = useUser();
    const empresaId = dataUsuario.empresaId
    const permisos = getPermisosEnPagina(permisosUsuario, 'Tipos')
    const datos = useParams() //Obtenemos los parámetros pasados por url { accion: "edit", id: "1" }
    const [nombre, setNombre] = useState('')
    const [clave, setClave] = useState('')
    const [aAlmacen, setAAlmacen] = useState(false)

    useEffect(() => {
        if (!isLogged) { navigate("/login"); }
    }, [isLogged]);

    const getDatosTipos = async () => {
        try {
            const {data: tipos} = await axios.get(`${getBasePath()}/tipos/${datos.id}`, getAuth());
            setNombre(tipos.nombre)
            setClave(tipos.clave)
            setAAlmacen(tipos.aAlmacen == "Si" ? true : false)
        } catch (error) {
            console.error(error)
            Swal.fire({
                title: 'Error',
                text: 'Hoteles no encontrados',
                icon: 'error'
              })
        }
    }

    const submitTipos = async (tiposObj) => {
        try {
            if (datos.accion === 'nuevo') 
                await axios.post(`${getBasePath()}/tipos`, tiposObj, getAuth());
            if (datos.accion === 'editar')
                await axios.put(`${getBasePath()}/tipos/${datos.id}`, tiposObj, getAuth());
 
                //await axios.post(`${getBasePath()}/tiposupdate`, tiposObj, getAuth());
            toastAlert('Tipos')
            navigate("/tipos")
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Error en la creación de los tipos',
                icon: 'error'
              })
            console.error(error)
        }
    }

    useEffect(() => {
        if(datos.accion !== 'nuevo') { getDatosTipos() }
    }, [datos])

    function handleSubmit (e) {
        e.preventDefault()
        const tiposObj = {
            id: datos.id,
            nombre,
            clave,
            aAlmacen: aAlmacen ? "Si" : "No"
        }
        submitTipos(tiposObj)
    }

    return isLogged && permisoAdecuado(permisos, datos.accion) ? (
        <div className="row gap-20 " style={{ position:"relative",height:"1116px" }} >
            <div className="masonry-item col-md-12" style={{ position:"absolute", left:"0%", top:"0px" }} >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{ datos.accion.charAt(0).toUpperCase() + datos.accion.slice(1).toLowerCase() } Registro</h6>
                    <div className="mT-30">
                        <Form onSubmit={handleSubmit}>                           
                            <div className='row'>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Nombre *</label>
                                    <Input type="text" className="form-control" id="nombre" value={nombre} onChange={({target}) => setNombre(target.value)} disabled={datos.accion === 'ver'} required />
                                </div>  
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Clave *</label>
                                    <Input type="text" className="form-control" id="clave" value={clave} onChange={({target}) => setClave(target.value)} disabled={datos.accion === 'ver'} required maxLength={3} />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Vuelve al almacen *</label>
                                    <Input type="checkbox" className="form-control" id="aAlmacen" checked={aAlmacen} onChange={({target}) => setAAlmacen(!aAlmacen)} disabled={datos.accion == 'ver'}/>
                                </div>
                            </div>                            
                            <button type="submit" className="btn btn-primary btn-color">Guardar</button>
                            <Link className="btn btn-secondary btn-color" to="/tipos" style={{marginLeft: '10px'}}>Atras</Link>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    ): (<SinPermisos/>)
}

export default Tiposform