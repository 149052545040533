import React, { useState, useEffect } from 'react'
import useUser from '../../hooks/useUser'
import { getPermisosEnPagina, getBasePath, getAuth, getImagenBasePath } from '../../Utils'
import SinPermisos from '../SinPermisos'
import axios from "axios"
import Select from 'react-select'
import { Link, useNavigate } from 'react-router-dom'
import { Card, CardBody, CardTitle, Input } from 'reactstrap'
import Swal from 'sweetalert2'
import './informes.scss'
import { JsonToExcel } from "react-json-to-excel"

const Informes = () => {
    const {isLogged, permisosUsuario, dataUsuario, nombreEmpresa} = useUser()
    const navigate = useNavigate();
    const permisos = getPermisosEnPagina(permisosUsuario, 'Informe General')
    const [informe, setInforme] = useState([])
    const [productos, setProductos] = useState([])
    const [fechaIni, setFechaIni] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [tipo, setTipo] = useState([])
    const [habitacionesA0, setHabitacionesA0] = useState(true)
    const [datosFechas, setDatosFechas] = useState([])
    const [getTipoOptions, setGetTipoOptions] = useState([])
    const [excel, setExcel] = useState([])
    const [informeGlobal, setInformeGlobal] = useState([])

    const [tipoimporte, setTipoimporte] = useState('importeVenta')
    const cargarDatosTipos = async () => {
        try {
            const {data: tipos} = await axios.get(`${getBasePath()}/tipos`, getAuth());
//            const tiposOptions = [{ label: 'Venta', value: 'Venta' }, ...tipos.map(({ nombre }) => ({label: nombre, value: nombre }))];
            const tiposOptions = [{ label: 'Tipo de incidencia...', value: '' }, ...tipos.map(({ nombre }) => ({ label: nombre, value: nombre }))];

            /*const tiposOptions = [
                { label: 'Venta', value: 'Venta' },
                ...tipos.map(({ nombre }) => {
                  if (nombre !== "Venta") {
                    return { label: nombre, value: nombre };
                  }
                }).filter(option => option) // Filtramos los elementos que sean undefined
              ];*/
              
            setGetTipoOptions(tiposOptions)            
        } catch (error){
            if (sessionStorage.getItem('jwt'))
                Swal.fire({icon: 'error', text: 'Error carga de tipos'})
        }
    }

    const getTipoImportes = [
        {value: 'importeVenta', label: "Venta"},
        {value: 'importeCoste', label: "Coste"}
    ]

    useEffect(() => {
        cargarDatosTipos()
        if (!isLogged) { navigate("/login"); }
      }, [isLogged]);

    const getInforme = async (filtros) => {
        try {
            const {data: informesData} = await axios.post(`${getBasePath()}/informes/general`, filtros, getAuth());

                const resultado = {}
                let auxFechas = {}
                informesData.forEach(item => {
                    let hotel = item.nombreHotel
                    let habitacion = item.nombreHabitacion
                    let lugar = item.lugar
                    let producto = item.idProducto

                    if(item[tipoimporte] > 0) {
                        auxFechas[`${item.nombreHabitacion}${item.idProducto}`] = (auxFechas[`${item.nombreHabitacion}${item.idProducto}`]) ? auxFechas[`${item.nombreHabitacion}${item.idProducto}`] + " " + item.fechaPosteo : item.fechaPosteo
                    }
                    if (!resultado[nombreEmpresa]) {
                        resultado[nombreEmpresa] = {}
                    }
                    if (!resultado[nombreEmpresa][hotel]) {
                        resultado[nombreEmpresa][hotel] = {}
                    }
                    if (!resultado[nombreEmpresa][hotel][habitacion]) {
                        resultado[nombreEmpresa][hotel][habitacion] = {}
                    }
                    if (!resultado[nombreEmpresa][hotel][habitacion]) {
                        resultado[nombreEmpresa][hotel][habitacion] = {}
                    }
                    if (!resultado[nombreEmpresa][hotel][habitacion][lugar]) {
                        resultado[nombreEmpresa][hotel][habitacion][lugar] = {}
                    }
                    if (!resultado[nombreEmpresa][hotel][habitacion][lugar][producto]) {
                        resultado[nombreEmpresa][hotel][habitacion][lugar][producto] = item[tipoimporte]
                    }
                });
            setInformeGlobal(informesData)
            
            //setExcel(informesData)
            setDatosFechas(auxFechas)
            setInforme(resultado)
        } catch (err) {
            console.error(err)
        }
    }

    const informePorRango = (fecha) => {
        const filtros = {
            empresa: dataUsuario.empresaId,
            tipo,
            periodo: fecha
        }
        getInforme(filtros)
    }

    const informePorFechas = () => {
        const filtros = {
            empresa: dataUsuario.empresaId,
            tipo,
            fecha_ini: fechaIni,
            fecha_fin: fechaFin,
        }
        getInforme(filtros)
    }

    const getData = async () => {
        try {
            const {data: response} = await axios.get(`${getBasePath()}/productos`, getAuth());
            setProductos(response)
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        getData()
    },[])

    const handleFechaInicioChange = ({target}) => { setFechaIni(target.value) }
    const handleFechaFinChange = ({target}) => { setFechaFin(target.value) }

    const handleFiltroFechaChange = (fecha) => {
        const boton = document.getElementById(fecha)
        if (!boton.classList.contains('btn-primary')) {
            const botonesFiltro = document.getElementsByClassName('filtroFecha')
            Array.from(botonesFiltro).forEach(el => {
                el.classList.remove('btn-primary')
                el.classList.add('btn-secondary')
            })
            boton.classList.add('btn-primary')
            boton.classList.remove('btn-secondary')
            informePorRango(fecha)
        }  
    }

    const objectToArray = (obj) => {
        return Object.keys(obj).map(key => obj[key])
    }

    const getImagenProducto = (id) => {
        const producto = productos.find(el => id == el.id)
        return producto != undefined ? producto.imagen : ''
    }

    const getImporteTotalEmpresa = (empresa) => {
        let importes = 0
        objectToArray(informe[empresa]).forEach(emp => {
            objectToArray(emp).forEach(hotel => { 
                objectToArray(hotel).forEach(hab => { 
                    objectToArray(hab).forEach(lug => { 
                    if (lug != null)
                        importes += parseInt(lug)
                    })
                })
            })
        })
        return importes
    }

    const getImporteTotalHotel = (hotel, empresa) => {
        let importes = 0
        objectToArray(informe[empresa][hotel]).forEach(emp => {
            objectToArray(emp).forEach(hab => { 
                objectToArray(hab).forEach(lug => { 
                    if (lug != null)
                        importes += parseInt(lug)
                })
            })
        })
        return importes
    }

    const getImporteTotalHabitacion = (habitacion, hotel, empresa) => {
        let importes = 0
        objectToArray(informe[empresa][hotel][habitacion]).forEach(hab => {
            objectToArray(hab).forEach(lug => {
                if (lug != null)
                    importes += parseInt(lug)
                })
        })
        return importes
    }

    const getImporteTotalLugar = (lugar, habitacion, hotel, empresa) => {
        let importes = 0
        objectToArray(informe[empresa][hotel][habitacion][lugar]).forEach(lug => {
            if (lug != null)
                importes += parseInt(lug)
        })
        return importes
    }

    const desplegar = (element) => {
        if (element.classList.contains('oculto')) {
            element.classList.remove('oculto')
        } else {
            element.classList.add('oculto')
        }
    } 

    const desplegarLugar = (lugar, habitacion, hotel, empresa) => {
        const card = document.getElementById(`lu-${empresa}-${hotel}-${habitacion}-${lugar}`)
        desplegar(card)
    }

    const desplegarHabiacion = (habitacion, hotel, empresa) => {
        const card = document.getElementById(`ha-${empresa}-${hotel}-${habitacion}`)
        desplegar(card)
    }

    const desplegarHotel = (hotel, empresa) => {
        const card = document.getElementById(`ho-${empresa}-${hotel}`)
        desplegar(card)
    }

    const desplegarEmpresa = (empresa) => {
        const card = document.getElementById(`e-${empresa}`)
        desplegar(card)
    }

    const devuelveFechas = async (empresa, hotel, habitacion, producto) => {
        console.log(datosFechas)
        if(document.getElementById(`fechas-${empresa}-${hotel}-${habitacion}-${producto}`)) {
            const periodo = (document.getElementsByClassName("filtroFecha btn-primary")[0].id) ? document.getElementsByClassName("filtroFecha btn-primary")[0].id : "sinperiodo"
            const fecha_ini = (fechaIni) ? fechaIni : "sinfecha"
            const fecha_fin = (fechaFin) ? fechaFin : "sinfecha"
            
            const {data: informesData} = await axios.get(`${getBasePath()}/informes/devuelvefechasventa/${tipo}/${hotel}/${habitacion}/${producto}/${periodo}/${fecha_ini}/${fecha_fin}`, getAuth());

            document.getElementById(`fechas-${empresa}-${hotel}-${habitacion}-${producto}`).innerHTML = ""
            document.getElementById(`fechas-${empresa}-${hotel}-${habitacion}-${producto}`).innerHTML = informesData
        }
    }

    const descargaInforme = async (tipoFiltro, datoEmpresa, datoHotel, datoHabitacion) => {
        let excelEstablecido = false;
      
        const filtros = {
          empresa: dataUsuario.empresaId,
          tipo,
          fecha_ini: fechaIni ? fechaIni : "sinfecha",
          fecha_fin: fechaFin ? fechaFin : "sinfecha",
          periodo: document.getElementsByClassName("filtroFecha btn-primary")[0]?.id || "sinperiodo", 
          oculta_habitaciones: habitacionesA0
        };
      
        switch (tipoFiltro) {
          case 'empresa':
            filtros.hotelNombre = datoHotel;
            filtros.empresaNombre = datoEmpresa;
            const { data: dataEmpresa } = await axios.post(`${getBasePath()}/informes/general`, filtros, getAuth());
            setExcel(dataEmpresa);
            excelEstablecido = true;
            break;
          case 'hotel':
            filtros.hotelNombre = datoHotel;
            const { data: dataHotel } = await axios.post(`${getBasePath()}/informes/general`, filtros, getAuth());
            setExcel(dataHotel);
            excelEstablecido = true;
            break;
          case 'habitacion':
            filtros.habitacionNombre = datoHabitacion;
            filtros.hotelNombre = datoHotel;
            const { data: dataHabitacion } = await axios.post(`${getBasePath()}/informes/general`, filtros, getAuth());
            setExcel(dataHabitacion);
            excelEstablecido = true;
            break;
          default:
            setExcel(informeGlobal);
            excelEstablecido = true;
        }
      
        if (excelEstablecido) {
            setTimeout(() => {
                document.getElementsByClassName("download-button")[0]?.click();
              }, 2000);
          
        }
      };
      
      

    return isLogged && permisos.ver ? (
    <main>
        <div style={{display:"none"}}><JsonToExcel id="excel" data={excel} fileName="informe" /></div>
        <h4 className="c-grey-900 mT-10 mB-30">Informes</h4>
        <Card id='filtros'>
            <div className='row'>
                <div className="mb-3 col-md-4">
                    <label className="form-label">Tipo</label>
                    <Select
                        options={getTipoOptions}
                        value={getTipoOptions.find(el => el.value == tipo)}
                        onChange={(target) => {setTipo(target.value)}}
                    />
                </div>
                <div className="mb-3 col-md-4">
                    <label className="form-label">Tipo Importe</label>
                    <Select
                        options={getTipoImportes}
                        value={getTipoImportes.find(el => el.value == tipoimporte)}
                        onChange={(target) => {setTipoimporte(target.value)}}
                    />
                </div>
                <div className="mb-3 col-md-4">
                    <label className="form-label">Oculta importes a 0</label><br />
                    <input 
                        className='form-check-input'
                        type = "checkbox"
                        defaultChecked = {habitacionesA0 != 0}
                        onClick={(e) => (habitacionesA0) ? setHabitacionesA0(false) : setHabitacionesA0(true)}
                    />
                </div>
            </div>
            <div className='d-flex mt-2 mb-1'>
                <button className="btn btn-secondary btn-color filtroFecha" id='H' onClick={() => handleFiltroFechaChange('H')} >Hoy</button>
                <button className="btn btn-secondary btn-color filtroFecha" id='S' onClick={() => handleFiltroFechaChange('S')} >Semana actual</button>
                <button className="btn btn-secondary btn-color filtroFecha" id='M' onClick={() => handleFiltroFechaChange('M')} >Mes actual</button>
                <button className="btn btn-secondary btn-color filtroFecha" id='MA' onClick={() => handleFiltroFechaChange('MA')} >Mes anterior</button>
                <button className="btn btn-secondary btn-color filtroFecha" id='A' onClick={() => handleFiltroFechaChange('A')} >Año actual</button>
            </div>
            <div className='d-flex mt-2'>
                <label >Fecha inicio</label>
                <Input 
                    type="date" 
                    className="form-control fechas" 
                    id="f_inicio" 
                    onChange={(e) => handleFechaInicioChange(e)}
                />
                <label >Fecha fin</label>
                <Input 
                    type="date" 
                    className="form-control fechas" 
                    id="f_fin" 
                    onChange={(e) => handleFechaFinChange(e)}
                />
                <button className="btn btn-primary btn-color" onClick={informePorFechas}>Buscar</button>&nbsp;&nbsp;
                <Link to='#' style={{ color:"black",marginRight:"4px", float:'right'}} className="btn cur-p btn-outline-primary" onClick={() => descargaInforme('todos', '', '')}><i className="ti ti-cloud-down"></i></Link>
            </div>
        </Card>
        
    { (Object.entries(informe).length > 0) ? (
        Array.from(Object.entries(informe)).map((empresa) => {
            return (
            <Card className='mb-2 ml-2 mt-2 card' style={{ display: 'block' }}>
                <div className='d-flex alert alert-primary' onClick={() => { desplegarEmpresa(empresa[0]) }}>
                    <CardTitle tag="h4" className=' '>
                        <Link to='#' style={{ color:"black",marginRight:"4px"}} 
                                className="btn cur-p btn-outline-primary" 
                                onClick={() => descargaInforme('empresa', empresa[0], '', '')}>
                            <i className="ti ti-cloud-down"></i>
                        </Link>
                        {empresa[0]}
                    </CardTitle>
                    <div className='importe'>{getImporteTotalEmpresa(empresa[0])}€</div>
                </div>
                <CardBody className='invoice-padding oculto' id={`e-${empresa[0]}`}>
                    {Array.from(Object.entries(empresa[1])).map((hotel) => {
                        return (
                            <Card className='mb-2 ml-2'>
                                <div className='d-flex alert alert-success' onClick={() => { desplegarHotel(hotel[0], empresa[0]) }}>
                                    <CardTitle tag="h4" >
                                        <Link to='#' style={{ color:"black",marginRight:"4px"}} 
                                                 className="btn cur-p btn-outline-primary" 
                                                 onClick={() => descargaInforme('hotel', empresa[0], hotel[0], '')}>
                                            <i className="ti ti-cloud-down"></i>
                                        </Link>
                                        {hotel[0]}
                                    </CardTitle>
                                    <div className='importe'>{getImporteTotalHotel(hotel[0], empresa[0])}€</div>
                                </div>
                                <CardBody className='invoice-padding oculto' id={`ho-${empresa[0]}-${hotel[0]}`}>
                                    {Array.from(Object.entries(hotel[1])).map((habitacion) => {
                                        return (
                                            //
                                            //Muestra solo las habitaciones con importe > 0
                                            //
                                            (getImporteTotalHabitacion(habitacion[0], hotel[0], empresa[0]) > 0 && habitacionesA0 &&
                                            <Card className='mb-2 ml-2'>
                                                <div className='d-flex alert alert-info' onClick={() => { desplegarHabiacion(habitacion[0], hotel[0], empresa[0]) }}>
                                                    <CardTitle tag="h4" className='title'>
                                                        <Link to='#' style={{ color:"black",marginRight:"4px"}} 
                                                                className="btn cur-p btn-outline-primary" 
                                                                onClick={() => descargaInforme('habitacion', empresa[0], hotel[0], habitacion[0])}>
                                                            <i className="ti ti-cloud-down"></i>
                                                        </Link>
                                                        {habitacion[0]}
                                                    </CardTitle>
                                                    <div className='importe'>{getImporteTotalHabitacion(habitacion[0], hotel[0], empresa[0])}€</div>
                                                </div>
                                                <CardBody className='invoice-padding oculto' id={`ha-${empresa[0]}-${hotel[0]}-${habitacion[0]}`}>
                                                    {Array.from(Object.entries(habitacion[1])).map((lugar) => {
                                                        return (
                                                            (getImporteTotalLugar(lugar[0], habitacion[0], hotel[0], empresa[0]) > 0 && 
                                                                <Card className='mb-6 ml-6'>
                                                                    <div className='d-flex alert alert-secondary ' onClick={() => { desplegarLugar(lugar[0], habitacion[0], hotel[0], empresa[0]) }}>
                                                                        <CardTitle tag="h4" >{lugar[0]}</CardTitle>
                                                                        <div className='importe'>{getImporteTotalLugar(lugar[0], habitacion[0], hotel[0], empresa[0])}€</div>
                                                                    </div>
                                                                    <CardBody className='invoice-padding oculto' id={`lu-${empresa[0]}-${hotel[0]}-${habitacion[0]}-${lugar[0]}`}>
                                                                        {Array.from(Object.entries(lugar[1])).map(producto => {
                                                                            return (
                                                                                (producto[1] > 0 &&
                                                                                    <div className='mb-3 col-md-3' style={{float:"left"}}> 
                                                                                        <div className='mr-1'><img src={`${getImagenBasePath()}/${getImagenProducto(producto[0])}`} onClick={() => {devuelveFechas(empresa[0], hotel[0], habitacion[0], producto[0])}} width='120px' height='120px'/></div>
                                                                                        <div className='coste'>{producto[1]}€</div>
                                                                                        <div className='coste' id={`fechas-${empresa[0]}-${hotel[0]}-${habitacion[0]}-${producto[0]}`}></div>
                                                                                    </div>
                                                                                )
                                                                            ) 
                                                                        })}
                                                                    </CardBody>
                                                                </Card>
                                                            )
                                                        )})}                                                    
                                                </CardBody>
                                            </Card>) ||
                                            //
                                            //Muestra las habitaciones con importe == 0
                                            //
                                            (getImporteTotalHabitacion(habitacion[0], hotel[0], empresa[0]) >= 0 && !habitacionesA0 &&
                                            <Card className='mb-2 ml-2'>
                                                <div className='d-flex alert alert-info' onClick={() => {
                                                    //
                                                    //Solo desplegamos las habitaciones con importe > 0
                                                    //
                                                    if (getImporteTotalHabitacion(habitacion[0], hotel[0], empresa[0]) > 0) 
                                                        desplegarHabiacion(habitacion[0], hotel[0], empresa[0]) 
                                                    }}>
                                                    <CardTitle tag="h4" className='title'>
                                                        {(getImporteTotalHabitacion(habitacion[0], hotel[0], empresa[0]) > 0 &&
                                                            <Link to='#' style={{ color:"black",marginRight:"4px"}} 
                                                                    className="btn cur-p btn-outline-primary" 
                                                                    onClick={() => descargaInforme('habitacion', empresa[0], hotel[0], habitacion[0])}>
                                                                <i className="ti ti-cloud-down"></i>
                                                            </Link>
                                                        )}
                                                        {habitacion[0]}
                                                    </CardTitle>
                                                    <div className='importe'>{getImporteTotalHabitacion(habitacion[0], hotel[0], empresa[0])}€</div>
                                                </div>
                                                <CardBody className='invoice-padding oculto' id={`ha-${empresa[0]}-${hotel[0]}-${habitacion[0]}`}>
                                                    {Array.from(Object.entries(habitacion[1])).map((lugar) => {
                                                        return (
                                                            <Card className='mb-6 ml-6'>
                                                                <div className='d-flex alert alert-secondary ' onClick={() => { desplegarLugar(lugar[0], habitacion[0], hotel[0], empresa[0]) }}>
                                                                    <CardTitle tag="h4" >{lugar[0]}</CardTitle>
                                                                    <div className='importe'>{getImporteTotalLugar(lugar[0], habitacion[0], hotel[0], empresa[0])}€</div>
                                                                </div>
                                                                <CardBody className='invoice-padding oculto' id={`lu-${empresa[0]}-${hotel[0]}-${habitacion[0]}-${lugar[0]}`}>
                                                                    {Array.from(Object.entries(lugar[1])).map(producto => {
                                                                        return (
                                                                            <div className='mb-3 col-md-3' style={{float:"left"}}> 
                                                                                <div className='mr-1'><img src={`${getImagenBasePath()}/${getImagenProducto(producto[0])}`} onClick={() => {devuelveFechas(empresa[0], hotel[0], habitacion[0], producto[0])}} width='120px' height='120px'/></div>
                                                                                <div className='coste'>{producto[1]}€</div>
                                                                                <div className='coste' id={`fechas-${empresa[0]}-${hotel[0]}-${habitacion[0]}-${producto[0]}`}></div>
                                                                            </div>
                                                                        ) 
                                                                    })}
                                                                </CardBody>
                                                            </Card>
                                                        )})}                                                    
                                                </CardBody>
                                            </Card>)
                                        )
                                    })}
                                </CardBody>
                            </Card>
                        )
                    })}
                </CardBody>
            </Card>
            )
        })
    ) : <div className="alert alert-danger" role="alert">
            Sin resultados para los parámetros seleccionados
        </div>
    }
         
    </main>) : (<SinPermisos/>)
}

export default Informes