import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useUser from '../../hooks/useUser'
import { getBasePath, getAuth, getPermisosEnPagina, permisoAdecuado, getImagenBasePath, toastAlert } from '../../Utils'
import axios from "axios"
import { Input, Form } from 'reactstrap'
import Swal from 'sweetalert2'
import SinPermisos from '../SinPermisos'
import Select from 'react-select'
import './Facturas.scss'

const Facturasform = () => {

    const navigate = useNavigate();
    const {isLogged, permisosUsuario, dataUsuario, hotelSelected} = useUser();
    const empresaId = dataUsuario.empresaId
    const permisos = getPermisosEnPagina(permisosUsuario, 'Facturas')
    const datos = useParams() //Obtenemos los parámetros pasados por url { accion: "edit", id: "1" }
    const [fecha, setFecha] = useState('')
    const [numero, setNumero] = useState('')
    const [formaPago, setFormaPago] = useState('')
    const [fechaVencimiento, setFechaVencimiento] = useState('')
    const [conceptos, setConceptos] = useState([])
    const [cerradaSNIni, setCerradaSNIni] = useState(false)
    const [cerradaSN, setCerradaSN] = useState(false)
    const [fin, setFin] = useState(false)
    const [observaciones, setObservaciones] = useState('')
    const [plantilla, setPlantilla] = useState('')
    let conceptosAux = []

    useEffect(() => {
        document.querySelector('.sidebar').style.display = 'block';
        document.querySelector('.navbar').style.display = 'block';
        document.querySelector('.page-container').style.paddingLeft = '280px';
        document.querySelector('footer').style.display = 'block';

        if (!isLogged) { 
            navigate("/login"); 
        }
    }, [isLogged]);

    const optionsFormaPago = [
    { value: 'Tarjeta', label: 'Tarjeta Bancaria' },
    { value: 'Transferencia', label: 'Transferencia' },
    { value: 'Metálico', label: 'Metálico' },
    { value: 'Domiciliación', label: 'Domiciliación' }
    ]

    const getDatosFacturas = async () => {
        try {
            //
            //Obtenemos los datos de la empresa para obtener la plantilla
            //
            const {data: empresa} = await axios.get(`${getBasePath()}/empresas/${empresaId}`, getAuth());
            setPlantilla(empresa.plantilla.substring(empresa.plantilla.lastIndexOf(" ") + 1))
            //
            //Obtenemos los datos de las facturas
            //
            const {data: facturas} = await axios.get(`${getBasePath()}/facturas/${datos.id}`, getAuth());
            setFecha(facturas.fecha)
            setNumero(facturas.numero)
            setFormaPago(facturas.formaPago)
            setFechaVencimiento(facturas.fechaVencimiento)
            setCerradaSNIni(facturas.cerradaSN == "S" ? true : false)
            console.log("getDatosFacturas", "cerradaSNIni", cerradaSNIni)
            setObservaciones(facturas.observaciones)
        } catch (error) {
            console.error(error)
            Swal.fire({
                title: 'Error',
                text: 'Factura no encontrada',
                icon: 'error'
              })
        }
    }

    function obtenerConceptos(datoFactura) {
        // Obtener el contenedor de conceptos
        const contenedor = document.getElementById("conceptos");
        // Obtener un arreglo de las líneas de concepto
        const lineas = Array.from(contenedor.children);
        // Mapear cada línea a un objeto con los valores de sus inputs
        const conceptos = lineas.map((linea) => ({
            facturaId: datoFactura.id,
            referencia: linea.querySelector(`input[id^="referencia"]`).value,
            descripcion: linea.querySelector(`input[id^="descripcion"]`).value,
            cantidad: Number(linea.querySelector(`input[id^="cantidad"]`).value),
            precio: Number(linea.querySelector(`input[id^="precio"]`).value),
            importe: Number(linea.querySelector(`input[id^="importe"]`).value),
            iva: linea.querySelector(`input[id^="iva"]`).value,
        }));
        return conceptos;
    }

    const submitFacturas = async (facturasObj) => {
        try {
            let datoFactura
            if (datos.accion === 'nuevo') 
                datoFactura = await axios.post(`${getBasePath()}/facturas`, facturasObj, getAuth());
            if (datos.accion === 'editar'){
                datoFactura = await axios.put(`${getBasePath()}/facturas/${datos.id}`, facturasObj, getAuth());
                //
                //Ahora inserto los conceptos
                //
                try{
                    await axios.post(`${getBasePath()}/facturasdetalle`, obtenerConceptos(datoFactura.data), getAuth());
                } catch (error) {
                    Swal.fire({
                        title: 'Error',
                        text: 'Error en la creación del detalle la Factura',
                        icon: 'error'
                    })
                    console.error(error)
                }
            }
            toastAlert('Facturas')
            if (datos.accion === 'nuevo') 
                navigate(`/facturasform/editar/${datoFactura.data.id}`)
            else
                navigate("/facturas")
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: 'Error en la creación de la Factura',
                icon: 'error'
              })
            console.error(error)
        }
    }

    const handleSubmit = async (e) => {

        e.preventDefault()
        const {data: hotel} = await axios.get(`${getBasePath()}/hoteles/${hotelSelected}`, getAuth())

        const facturasObj = {
            hotelId: hotelSelected,
            fecha,
            numero,
            domicilioSocial: hotel.domicilioSocial,
            domicilioFiscal: hotel.domicilioFiscal,
            direccionEnvioFactura: hotel.direccionEnvioFactura,
            nombreCliente: hotel.nombreCliente,
            formaPago,
            domicilioPago: hotel.domicilioPago,
            observaciones,
            fechaVencimiento,
            cifCliente: hotel.cifnif,
            numeroCuenta: hotel.numeroCuenta,
            pie1: hotel.pie1,
            pie2: hotel.pie2,
            cerradaSN: cerradaSN ? "S" : "N"
        }
        submitFacturas(facturasObj)
    }
    function calculaImporte(linea){
        let cantidad = document.getElementById(`cantidad${linea}`)?.value
        let precio = document.getElementById(`precio${linea}`)?.value
        let iva =  document.getElementById(`iva${linea}`)?.value
        if (cantidad != undefined){
            let importe = document.getElementById(`importe${linea}`)
            importe.value = parseFloat(((cantidad * precio) * `1.${iva}`).toFixed(2))
        } 
    }
    const agregarConcepto = (valorIva, valorReferencia, valorDescripcion, valorCantidad, valorPrecio, valorImporte, id) => {
        async function eliminarConcepto(lineaConcepto) {
            const linea = document.getElementById(lineaConcepto);
            if (linea) {
              linea.remove();
              await axios.delete(`${getBasePath()}/facturasdetalle/${id}`, getAuth())              

            }
        }
        function LineaConcepto({ index }) {
            console.log("cerradaSNIni", cerradaSNIni)
          return (
            <div className='row' id={`lineaConcepto-${index}`}>
              <div className="mb-1 col-md-1">
                <label className="form-label">IVA *</label>
                <Input type="text" className="form-control" id={`iva${index}`} onChange={() => calculaImporte(index)} defaultValue={valorIva || 10} required/>
              </div>
              <div className="mb-1 col-md-1">
                <label className="form-label">Referencia *</label>
                <Input type="text" className="form-control" id={`referencia${index}`} defaultValue={valorReferencia} required/>
              </div>
              <div className="mb-4 col-md-5">
                <label className="form-label">Descripción *</label>
                <Input type="text" className="form-control" id={`descripcion${index}`} defaultValue={valorDescripcion} required/>
              </div>
              <div className="mb-1 col-md-1">
                <label className="form-label">Cantidad *</label>
                <Input type="text" className="form-control importesFacturas" id={`cantidad${index}`} onChange={() => calculaImporte(index)} defaultValue={valorCantidad} required/>
              </div>
              <div className="mb-1 col-md-1">
                <label className="form-label">Precio *</label>
                <Input type="text" className="form-control importesFacturas" id={`precio${index}`} onChange={() => calculaImporte(index)} defaultValue={valorPrecio} required/>
              </div>
              <div className="mb-1 col-md-1">
                <label className="form-label">Importe *</label>
                <Input type="text" className="form-control importesFacturas" id={`importe${index}`} onChange={() => calculaImporte(index)} defaultValue={valorImporte} required/>
              </div>
              <div className="mb-1 col-md-1">
                { !document.getElementById("cerradaSN").checked && 
                    <button
                    type="button"
                    style={{marginTop:"30px"}}
                    className="btn btn-sm btn-danger"
                    onClick={() => eliminarConcepto(`lineaConcepto-${index}`)}
                    >
                    <i className="ti ti-trash"></i>
                    </button>
                }
              </div>
            </div>
          );
        }
        const nuevaLinea = <LineaConcepto index={id} />
        if(valorDescripcion != ""){
            return nuevaLinea
        }
        else{
            setConceptos([...conceptos, nuevaLinea]);            
        }
    }


    const getDatosFacturasDetalle = async () => {
        try {
            console.log("cerradaSNIni", cerradaSNIni)
            const {data: facturas} = await axios.get(`${getBasePath()}/facturasdetalle/${datos.id}`, getAuth());
            facturas.forEach((linea) => {
                conceptosAux.push(agregarConcepto(linea.iva, linea.referencia, linea.descripcion, linea.cantidad, linea.precio, linea.importe, linea.id))
            })
            setConceptos([...conceptos, conceptosAux])
        } catch (error) {
            console.error(error)
            Swal.fire({
                title: 'Error',
                text: 'Factura no encontrada',
                icon: 'error'
              })
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if(datos.accion !== 'nuevo') { 
                await getDatosFacturas() 
                await getDatosFacturasDetalle()
                setFin(true)
            }
        }
        fetchData()
    }, [datos])

    useEffect(() => {
        for(let i=0;i<conceptos.length;i++)
            calculaImporte(i)
    }, [fin])
    return isLogged && permisoAdecuado(permisos, datos.accion) ? (
        <div className="row gap-20 " style={{ position:"relative",height:"1116px" }} >
            <div className="masonry-item col-md-12" style={{ position:"absolute", left:"0%", top:"0px" }} >
                <div className="bgc-white p-20 bd">
                    <h6 className="c-grey-900">{ datos.accion.charAt(0).toUpperCase() + datos.accion.slice(1).toLowerCase() } Registro</h6>
                    <div className="mT-30">
                        <Form onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Número *</label>
                                    <Input type="text" className="form-control" id="numero" value={numero} onChange={({target}) => setNumero(target.value)} disabled={datos.accion === 'ver'} required />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Fecha *</label>
                                    <Input type="date" className="form-control" id="fecha" defaultValue={fecha} onChange={({target}) => setFecha(target.value)} disabled={datos.accion === 'ver'} required />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Forma Pago *</label>
                                    <Select 
                                        options={optionsFormaPago} 
                                        value={optionsFormaPago.find(el => el.value === formaPago)}
                                        onChange={(target) => setFormaPago(target.value)}
                                        isDisabled={datos.accion === 'ver'}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Fecha Vencimiento *</label>
                                    <Input type="date" className="form-control" id="fechaVencimiento" value={fechaVencimiento} onChange={({target}) => setFechaVencimiento(target.value)} disabled={datos.accion === 'ver'} required />
                                </div>
                                <div className="mb-3 col-md-4">
                                    <label className="form-label">Cerrada Si/No </label><br />
                                    <input className="form-check-input" type="checkbox" id="cerradaSN" checked={cerradaSNIni ? true : cerradaSN} onChange={({target}) => setCerradaSN(!cerradaSN)} disabled={datos.accion == 'ver'} />
                                </div>
                            </div>
                            {datos.accion !== 'nuevo' && (
                                <div>
                                  <h6 className="c-grey-900">Conceptos &nbsp;&nbsp;<button type="button" className="btn btn-info btn-color" onClick={() => agregarConcepto("", "", 0, 0, 0, 0)}>+</button></h6>
                                  <hr style={{height:"2px", width:"100%", borderWidth:"0", color:"#00bcd4", backgroundColor:"#00bcd4"}} />
                                  <div id="conceptos"> 
                                    {conceptos}
                                  </div>
                                </div>
                              )}
                              <div className='row' style={{marginBottom:"10px"}}>
                                <div className="mb-9 col-md-12">
                                    <label className="form-label">Observaciones </label>
                                    <textarea className="form-control" value={observaciones} onChange={(e) => setObservaciones(e.target.value)}></textarea>
                                </div>
                            </div>
                            { cerradaSNIni ? '' : <button type="submit" className="btn btn-primary btn-color">Guardar</button> }
                            <Link className="btn btn-secondary btn-color" to="/facturas" style={{marginLeft: '10px'}}>Atras</Link>
                            <Link className="btn btn-success btn-color" to={`/factura${plantilla}/${datos.id}`} style={{marginLeft: '10px'}}>Generar</Link>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    ): (<SinPermisos/>)
}
export default Facturasform