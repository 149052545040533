import React, { useState, useEffect } from 'react'
import useUser from '../../hooks/useUser'
import axios from "axios"
import './previsualizarfacturas.scss'
import { getBasePath, getAuth, getPermisosEnPagina, permisoAdecuado, getImagenBasePath, toastAlert } from '../../Utils'
import { useParams, Link, useNavigate } from 'react-router-dom';
import SinPermisos from '../SinPermisos'

const Factura3 = () => {
    const navigate = useNavigate();
    const {isLogged, permisosUsuario, dataUsuario, hotelSelected, nombreEmpresa} = useUser();
    const permisos = getPermisosEnPagina(permisosUsuario, 'Facturas')
    const datos = useParams() //Obtenemos los parámetros pasados por url
    const [imagen, setImagen]  = useState('')
    const [fecha, setFecha] = useState('')
    const [numero, setNumero] = useState('')
    const [domicilioSocial, setDomicilioSocial] = useState('')
    const [domicilioFiscal, setDomicilioFiscal] = useState('')
    const [direccionEnvioFactura, setDireccionEnvioFactura] = useState('')
    const [nombreCliente, setNombreCliente] = useState('')
    const [formaPago, setFormaPago] = useState('')
    const [domicilioPago, setDomicilioPago] = useState('')
    const [observaciones, setObservaciones] = useState('')
    const [fechaVencimiento, setFechaVencimiento] = useState('')
    const [cifCliente, setCifCliente] = useState('')
    const [pie1, setPie1] = useState('')
    const [pie2, setPie2] = useState('')
    const [fechaCreacion, setFechaCreacion] = useState('')
    const [datosFacturasDet, setDatosFacturasDet] = useState([])
    const [sumaImporte, setSumaImporte] = useState(0)
    const [sumaPrecio, setSumaPrecio] = useState(0)
    const [numCuenta, setNumCuenta] = useState(0)

    const getDatos = async () => {
        const {data: facturas} = await axios.get(`${getBasePath()}/facturas/${datos.id}`, getAuth())
        const {data: datosFacturasDet} = await axios.get(`${getBasePath()}/facturasdetalle/${datos.id}`, getAuth())
        setDatosFacturasDet(datosFacturasDet.filter(e => e.importe > 0))
        setFecha(facturas.fechaMostrar)
        setNumero(facturas.numero)
        setDomicilioSocial(facturas.domicilioSocial)
        setDomicilioFiscal(facturas.domicilioFiscal)
        setDireccionEnvioFactura(facturas.direccionEnvioFactura)
        setNombreCliente(facturas.nombreCliente)
        setFormaPago(facturas.formaPago)
        setDomicilioPago(facturas.domicilioPago)
        setObservaciones(facturas.observaciones)
        setFechaVencimiento(facturas.fechaVencimientoMostrar)
        setCifCliente(facturas.cifCliente)
        setPie1(facturas.pie1)
        setPie2(facturas.pie2)

        const {data: datosFacturas} = await axios.get(`${getBasePath()}/devuelvedatosfacturas/${datos.id}`, getAuth());
        setImagen(datosFacturas[0].imagen)
        setNumCuenta(datosFacturas[0].numCuenta)

        let vsumaImporte = 0
        let vsumaPrecio = 0

        for(let clave in datosFacturasDet){         
            vsumaImporte = parseFloat(vsumaImporte)
            vsumaImporte += parseFloat(datosFacturasDet[clave]?.importe || 0)            
            vsumaPrecio = parseFloat(vsumaPrecio)
            vsumaPrecio += parseFloat(datosFacturasDet[clave]?.precio || 0)
        }
        setSumaImporte(vsumaImporte)
        setSumaPrecio(vsumaPrecio)
    }
    useEffect(() => {
        if (!isLogged) { 
            navigate("/login"); 
        }
        else {
            document.querySelector('.sidebar').style.display = 'none';
            document.querySelector('.navbar').style.display = 'none';
            document.querySelector('.page-container').style.paddingLeft = '0';
            document.querySelector('footer').style.display = 'none';
            getDatos()
        }
    }, [isLogged]);

    const estiloTablaCab = {
        border: "1px solid black",
        padding:"5px 14px",
        textAlign:"center"
    }
    const estiloTablaDet = {
        border: "1px solid black",
        padding:"5px 14px",    
    }
    const estiloTablaDetNum = {
        border: "1px solid black",
        padding:"5px 14px",
        textAlign:"right"
    }

    const devuelveConceptos = () => {
        const datos = []
        let vsumaImporte = 0
        let vsumaPrecio = 0

        for (let i=0; i<33; i++){
            if (i == 32){
                datos.push(<tr style={{fontSize:'10px'}}>
                    <td style={{borderBottom: '1px solid black', borderLeft:'1px solid black', paddingLeft:'15px', width:'10%'}}>{ datosFacturasDet[i]?.referencia }</td>
                    <td style={{borderBottom: '1px solid black', borderLeft:'1px solid black', paddingLeft:'15px'}}>{ datosFacturasDet[i]?.descripcion }</td>
                    <td style={{borderBottom: '1px solid black', borderLeft:'1px solid black', textAlign:'right', paddingRight:'15px', width:'10%'}}>{ datosFacturasDet[i]?.cantidad }</td>
                    <td style={{borderBottom: '1px solid black', borderLeft:'1px solid black', textAlign:'right', paddingRight:'15px', width:'10%'}}>{ datosFacturasDet[i]?.precio }</td>
                    <td style={{borderBottom: '1px solid black', borderLeft:'1px solid black', textAlign:'right', paddingRight:'15px', borderRight:'1px solid black', width:'10%'}}>{ datosFacturasDet[i]?.importe }</td>
                </tr>)
            } else {
                datos.push(<tr style={{fontSize:'10px'}}>
                    <td style={{borderLeft:'1px solid black', paddingLeft:'15px', width:'10%'}}>{ datosFacturasDet[i]?.referencia }&nbsp;</td>
                    <td style={{borderLeft:'1px solid black', paddingLeft:'15px'}}>{ datosFacturasDet[i]?.descripcion }</td>
                    <td style={{borderLeft:'1px solid black', textAlign:'right', paddingRight:'15px', width:'10%'}}>{ datosFacturasDet[i]?.cantidad }</td>
                    <td style={{borderLeft:'1px solid black', textAlign:'right', paddingRight:'15px', width:'10%'}}>{ datosFacturasDet[i]?.precio }</td>
                    <td style={{borderLeft:'1px solid black', textAlign:'right', paddingRight:'15px', borderRight:'1px solid black', width:'10%'}}>{ datosFacturasDet[i]?.importe }</td>
                </tr>)
            }
            vsumaImporte = parseFloat(vsumaImporte);
            vsumaImporte += parseFloat(datosFacturasDet[i]?.importe || 0)
            vsumaPrecio = parseFloat(vsumaPrecio)
            vsumaPrecio += parseFloat(datosFacturasDet[i]?.precio || 0)
        }
        return datos;
    }
    
    return isLogged && permisoAdecuado(permisos, 'editar') ? (
        <div class="container" style={{marginTop:'-65px', fontSize:'12px'}}>
            <table style={{width:'100%'}}>
                <tr>
                    <td style={{width:"50%"}}>
                        <img id="imagen" 
                        className='user-avatar rounded mr-2 my-25 cursor-pointer'
                        src={`${getImagenBasePath()}/${imagen}`}
                        alt='Imagen empresa'
                        style={{maxWidth:'100%'}}
                        />
                    </td>
                    <td style={{width:'50%',verticalAlign:'top', height:'10px'}}>
                        &nbsp;
                    </td>
                </tr> 
            </table>
            <table style={{width:'100%',marginTop:"20px"}}>
                <tr style={{verticalAlign:'top'}}>
                    <td style={{width:"50%", verticalAlign:'bottom', paddingBottom:'1px'}}>
                        <table style={{width:"100%"}}>
                            <tr style={{fontWeight:'bold'}}>
                                <td style={{borderBottom: "1px solid black"}}>FACTURA</td>
                                <td style={{borderBottom: "1px solid black"}}>FECHA</td>
                            </tr>
                            <tr style={{fontSize:'10px'}}>
                                <td >{ numero }</td>
                                <td >{ fecha }</td>
                            </tr>
                        </table>
                    </td>
                    <td><br />
                        <span style={{marginLeft:"10px", fontWeight:"bold"}}>Domicilio social:</span>
                         {domicilioSocial}
                    </td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td>CIF:{ nombreCliente }</td>
                </tr>
                <tr>
                    <td style={{paddingTop:'30px'}} colspan="4">
                        <table style={{width:'100%'}}>
                            <thead>
                                <tr style={{fontWeight:"bold"}}>
                                    <td style={{padding:"5px 14px", borderBottom:"1px solid black"}}>Referencia</td>
                                    <td style={{padding:"5px 14px", borderBottom:"1px solid black"}}>Descripcion</td>
                                    <td style={{padding:"5px 14px", borderBottom:"1px solid black"}}>Cantidad</td>
                                    <td style={{padding:"5px 14px", borderBottom:"1px solid black"}}>Precio</td>
                                    <td style={{padding:"5px 14px", borderBottom:"1px solid black"}}>Importe</td>
                                </tr>
                            </thead>
                            { devuelveConceptos() }
                        </table>
                    </td>
                </tr>
            </table>
            <table style={{width:'100%'}}>
                <tr>
                    <td>
                        <table style={{width:'100%', marginTop:"-3px"}}>
                            <tr>
                                <td colSpan={2} style={{borderLeft:"1px solid gray", width:'58.9%',padding:"5px 14px"}}><span style={{fontWeight:'bold'}}>FORMA DE PAGO:</span> <span style={{fontSize:"10px"}}>{ formaPago }</span></td>
                                <td style={{width:'25%',borderRight: "3px solid black", textAlign:"right", paddingRight:"15px",fontWeight:'bold'}}>Subtotal:</td>
                                <td style={{borderTop: "1px solid black", borderBottom:"1px solid black", borderRight:"1px solid gray", fontSize:"10px", textAlign:"right", paddingRight:"14px"}}>{ sumaPrecio.toFixed(2) }</td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{borderLeft:"1px solid gray", width:'58.9%',padding:"5px 14px"}}><span style={{fontWeight:'bold'}}>Número de Cuenta:</span> <span style={{fontSize:"10px"}}>{ numCuenta }</span></td>
                                <td style={{width:'25%',borderRight: "3px solid black", textAlign:"right", paddingRight:"15px",fontWeight:'bold'}}>&nbsp;</td>
                                <td style={{borderTop: "1px solid black", borderBottom:"1px solid black", borderRight:"1px solid gray", fontSize:"10px", textAlign:"right", paddingRight:"14px"}}>&nbsp;</td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{borderLeft:"1px solid gray",borderBottom:"1px solid black", width:'58.9%',padding:"5px 14px"}}><span style={{fontWeight:'bold'}}>Vencimiento:</span> <span style={{fontSize:"10px"}}>{ fechaVencimiento }</span></td>
                                <td style={{borderBottom:"1px solid black", width:'25%',borderRight: "3px solid black", textAlign:"right", paddingRight:"15px",fontWeight:'bold'}}>Total</td>
                                <td style={{borderTop: "3px solid black", borderBottom:"1px solid black", borderRight:"1px solid gray", fontSize:"10px", textAlign:"right", paddingRight:"14px"}}>{ sumaImporte.toFixed(2) }</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <label>{ pie1 }</label>
        </div>
    ): (<SinPermisos/>)

}

export default Factura3